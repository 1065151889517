const sidebar = document.querySelector("#js-sidebar-course");
const contentLandingCourse = document.querySelector(".js-course-page-content");

if (sidebar) {
  const heightSidebar = sidebar.offsetHeight;
  const heightLandingCourse = contentLandingCourse.offsetHeight;

  const convertToStickySidebar = () => {
    const sidebarIsSticky = sidebar.classList.contains("sidebar-course--sticky");
    const contentLandingTopIsVisible = contentLandingCourse.getBoundingClientRect().top > -50;
    const sidebarBottomIsVisible =
      sidebar.getBoundingClientRect().bottom < window.innerHeight - 200;
    const sidebarReachedBottom = sidebar.getBoundingClientRect().top < 20;
    const stickyFinished = sidebar.classList.contains("sidebar-course--sticky--finished");

    if (
      !sidebarIsSticky &&
      sidebarBottomIsVisible &&
      !contentLandingTopIsVisible &&
      !stickyFinished
    ) {
      sidebar.classList.add("sidebar-course--sticky");
    }

    if (sidebarIsSticky && window.scrollY === 0) {
      sidebar.classList.remove("sidebar-course--sticky");
    }

    if (sidebarIsSticky && sidebarReachedBottom) {
      if (!stickyFinished) {
        sidebar.classList.add("sidebar-course--sticky--finished");
      }
    } else if (stickyFinished && !sidebarReachedBottom) {
      sidebar.classList.remove("sidebar-course--sticky--finished");
    }
  };

  const enable = () => {
    sidebar.classList.remove("sidebar-course--with-fixed");
    if (heightSidebar < heightLandingCourse) {
      window.addEventListener("scroll", convertToStickySidebar);
    }
  };

  const disable = () => {
    sidebar.classList.remove("sidebar-course--sticky");
    window.removeEventListener("scroll", convertToStickySidebar);
  };

  enquire.register(Domestika.enquire.tablet_down, { match: disable });
  enquire.register(Domestika.enquire.tablet_up, { match: enable });
}
