import { Controller } from "@hotwired/stimulus";
import { trackAmplitudeEvents } from "../../js/utils/events/amplitude";

export default class extends Controller {
  static targets = ["portlet"];

  selectOption(event) {
    const portlet = event.currentTarget;
    this.portletTargets.forEach((item) => item.removeAttribute("open"));
    portlet.setAttribute("open", "");

    const amplitudeProps = JSON.parse(portlet.dataset.amplitudeProps || {});
    const { eventName, eventProperties } = amplitudeProps;
    trackAmplitudeEvents(eventName, eventProperties);
  }
}
