// Common popover
const popoverShareButtons = document.querySelectorAll(".js-popover-share-buttons");
const popoverLists = document.querySelector(".js-courses-lists-popover");
const shareButton = document.querySelectorAll(".js-share-open-popover");
const addCourseToList = document.querySelector(".js-add-course-to-list");

if (shareButton) {
  shareButton.forEach((element, index) => {
    element.addEventListener("click", () => {
      if (popoverShareButtons[index]) {
        popoverShareButtons[index].classList.toggle("show");
      }
    });

    // Close + List
    if (popoverLists) {
      popoverLists.classList.remove("show");
    }
  });
}

// When open + List
if (addCourseToList) {
  addCourseToList.addEventListener("click", () => {
    popoverShareButtons.forEach((element) => {
      element.classList.remove("show");
    });
  });
}

// When click outside
document.querySelector("body").addEventListener("click", (event) => {
  const isClickedinButton = event.target.classList.contains("js-share-open-popover");
  const isClickedinButtonIcon = event.target.parentNode.classList.contains("js-share-open-popover");
  const isClickedInPopover = event.target.closest(".js-popover-share-buttons");

  if (!isClickedinButton && !isClickedinButtonIcon && !isClickedInPopover) {
    popoverShareButtons.forEach((element) => {
      element.classList.remove("show");
    });
  }
});
