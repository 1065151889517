import { deleteCookie, getCookie } from "../../../packs/utils/cookies";

const { pathname, search } = window.location;
const cookieExists = getCookie("fresh_response");
let params;

if (cookieExists) {
  deleteCookie("fresh_response");
} else {
  if (search) {
    params = `${search}&no_cache=1`;
  } else {
    params = "?no_cache=1";
  }

  fetch(pathname + params, {
    method: "HEAD",
  });
}
