const courseNavStatic = document.getElementById("js-course-navigation-static");
const courseNavFixed = document.getElementById("js-course-navigation-fixed");
const body = document.querySelector("body");

if (courseNavFixed) {
  const convertCourseNavigationToSticky = () => {
    const bounding = courseNavStatic.getBoundingClientRect();
    const delimiter = bounding.top;

    courseNavFixed.classList.toggle("course-header-new__nav--fixed--show", delimiter < 0);
    body.classList.toggle("course-navigation-show", delimiter < 0);
  };

  const enable = () => {
    window.addEventListener("scroll", convertCourseNavigationToSticky);
  };

  const disable = () => {
    window.removeEventListener("scroll", convertCourseNavigationToSticky);
  };

  enquire.register(Domestika.enquire.tablet_down, { match: disable });
  enquire.register(Domestika.enquire.tablet_up, { match: enable });
}
