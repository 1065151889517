import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["spacer", "innerMenu", "gradient"];

  connect() {
    this.addGradient();
  }

  addGradient() {
    if (this.isScrollable()) {
      this.spacerTarget.classList.remove("hidden");
      this.gradientTarget.classList.remove("hidden");
    } else {
      this.spacerTarget.classList.add("hidden");
      this.gradientTarget.classList.add("hidden");
    }
  }

  isScrollable() {
    const wrapperWidth = this.element.clientWidth;
    const childrenWidth = this.innerMenuTarget.scrollWidth;

    return childrenWidth > wrapperWidth;
  }
}
