const promoHeader = document.getElementById("js-promo-header-new");
const sidebar = document.getElementById("js-sidebar-course");
const contentLandingCourse = document.getElementsByClassName("js-course-page-content")[0];
if (promoHeader) {
  const convertToStickyPromoHeader = () => {
    const heightSidebar = sidebar?.offsetHeight;
    const convertToSticky = Math.abs(heightSidebar - 450);
    const heightLandingCourse = contentLandingCourse.offsetHeight;
    if (window.scrollY > convertToSticky) {
      promoHeader.classList.add("promo-header-new--stuck");
      document.body.classList.add("body--with-promo-header");
    } else {
      promoHeader.classList.remove("promo-header-new--stuck");
      document.body.classList.remove("body--with-promo-header");
    }

    if (window.scrollY > heightLandingCourse - (heightSidebar - 50)) {
      promoHeader.classList.remove("promo-header-new--stuck");
      document.body.classList.remove("body--with-promo-header");
    } else if (
      window.scrollY < heightLandingCourse - (heightSidebar - 50) &&
      window.scrollY > convertToSticky
    ) {
      promoHeader.classList.add("promo-header-new--stuck");
      document.body.classList.add("body--with-promo-header");
    }
  };

  const enable = () => {
    window.addEventListener("scroll", convertToStickyPromoHeader);
  };

  const disable = () => {
    promoHeader.classList.remove("promo-header-new--stuck");
    window.removeEventListener("scroll", convertToStickyPromoHeader);
  };

  enquire.register(Domestika.enquire.tablet_down, { match: disable });
  enquire.register(Domestika.enquire.tablet_up, { match: enable });
}
