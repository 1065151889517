// ----- Base
import "../../../../js/base";
import "../../../../js/atoms/toggle/translate-contents";
import "../../../../js/molecules/course-navigation/course-navigation-new";
import "../../../../js/molecules/popover/teacher-popover";
import "../../../../js/molecules/footer/promo-footer";
import "../../../../js/molecules/popover/sharing-buttons-popover";
import "../../../../js/molecules/promo-header/promo-header-new";
import "../../../../js/organism/sidebar/sidebarCourse";
import "../../../../js/molecules/fields/copyToClipboardField";
import "../../../../js/utils/getTrackingAndCookies";

import { Application } from "@hotwired/stimulus";
import DsPortletsController from "../../../ds/portlets_controller";
import OneClickCheckout from "../../../ds/one_click_checkout_controller";
import DsSectionNavigation from "../../../ds/section_navigation_controller";

window.Stimulus = Application.start();
window.Stimulus.register("ds-portlets", DsPortletsController);
window.Stimulus.register("ds-section-navigation", DsSectionNavigation);
window.Stimulus.register("one-click-checkout", OneClickCheckout);

document.addEventListener("turbo:frame-load", Domestika.CourseCardEvents.handle);

Domestika.courses_controller.show_new();
