const wrappers = document.querySelectorAll(".js-teacher-popover-wrapper");
const allPopovers = document.querySelectorAll(".js-teacher-popover");
const allPopoverLinks = document.querySelectorAll(".js-teacher-popover-link");

let showTimer = null;
let hideTimer = null;

const hideAllPopovers = () => allPopovers.forEach((el) => el.classList.remove("show"));

const getPopover = (wrapper) => {
  const id = wrapper.dataset.teacherPopoverWrapper;
  return document.querySelector(`[data-teacher-popover="${id}"]`);
};

const handleMouseEnter = (event) => {
  const wrapper = event.target;
  const popover = getPopover(wrapper);
  if (!popover) return;

  showTimer = setTimeout(function show() {
    hideAllPopovers();
    popover.classList.add("show");
    wrapper.appendChild(popover);
  }, 150);

  clearTimeout(hideTimer);
};

const handleMouseLeave = (event) => {
  const popover = getPopover(event.target);
  if (!popover) return;

  hideTimer = setTimeout(function hide() {
    popover.classList.remove("show");
  }, 150);

  clearTimeout(showTimer);
};

const handleClick = (event) => {
  event.preventDefault();
};

const popoversEnable = (el) => {
  el.addEventListener("mouseenter", handleMouseEnter);
  el.addEventListener("mouseleave", handleMouseLeave);
};

const enable = () => {
  wrappers.forEach((el) => {
    popoversEnable(el);
  });

  allPopoverLinks.forEach((el) => {
    el.addEventListener("click", handleClick);
  });
};

const disable = () => {
  wrappers.forEach((el) => {
    el.removeEventListener("mouseenter", handleMouseEnter);
    el.removeEventListener("mouseleave", handleMouseLeave);
  });

  allPopoverLinks.forEach((el) => {
    el.removeEventListener("click", handleClick);
  });
};

// this is to activate mediaqueries
enquire.register(Domestika.enquire.tablet_down, { match: disable });
enquire.register(Domestika.enquire.tablet_up, { match: enable });
