const PromoFooterInit = () => {
  if (!("IntersectionObserver" in window)) return;

  const promoFooter = document.querySelector(".js-promo-footer");
  const observedElement = document.querySelector(".js-promo-footer-observer");

  // We only continue when a promo footer is available.
  if (!promoFooter) return;

  // Show promo footer directly if it's not an observer
  if (!observedElement) {
    promoFooter.classList.add("is-visible");
    return;
  }

  const options = {
    rootMargin: "-140px",
    threshold: 0,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((el) => {
      promoFooter.classList.toggle("is-visible", !el.isIntersecting);
    });
  }, options);

  observer.observe(observedElement);
};

enquire.register(Domestika.enquire.tablet_down, { match: PromoFooterInit });
