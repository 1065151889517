class ContentTranslate {
  init() {
    this.initiaLinks = document.querySelectorAll(".js-content-translation-link");
    if (this.initiaLinks) {
      this.initiaLinks.forEach((link) => {
        link.addEventListener("click", (element) => {
          const parent = element.target.closest(".js-content-translation");
          const showLink = parent.querySelector(".js-content-translation-toggle-show");
          const translatedContent = parent.querySelector(".js-content-translation-text");
          const hideLink = parent.querySelector(".js-content-translation-toggle-hide");
          const { url } = element.target.dataset;

          // Si no existe todavía la traducción, la llamamos
          // Si lo está, la mostramos u ocultamos
          if (translatedContent && translatedContent.innerHTML.trim()) {
            showLink.classList.toggle("d-none");
            hideLink.classList.toggle("d-none");
            translatedContent.classList.toggle("d-none");
          } else {
            const loader = parent.querySelector(".js-content-translation-loading");
            loader.classList.remove("d-none");
            $.ajax({
              url,
            }).done((response) => {
              loader.classList.add("d-none");
              showLink.classList.add("d-none");
              hideLink.classList.remove("d-none");

              if (response.length > 1) {
                translatedContent.innerHTML = response;
                Domestika.code_editor.handle_viewer();
              } else {
                showLink.remove();
                hideLink.remove();
              }
            })
          }
        });
      });
    } else {
      throw new Error("No hay contenidos disponible para traducir en esta vista.");
    }
  }
}

Domestika.contentTranslate = new ContentTranslate();
