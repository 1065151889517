import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["options", "confirm"];

  handleShow() {
    this.optionsTarget.removeAttribute("open");
    this.confirmTarget.setAttribute("open", true);
  }

  handleCancel() {
    this.confirmTarget.removeAttribute("open");
    this.optionsTarget.setAttribute("open", true);
  }
}
